import { wxworkConfig } from "@/api/user";
import { message } from "ant-design-vue";
const ua =
navigator && navigator.userAgent
  ? navigator.userAgent.toLowerCase() || ""
  : "";
const isWxWork = /wxwork/i.test(ua); // 是否企业微信
let obj = {
  isWxWork: isWxWork,
  agentConfig() {
    console.log('wxwork.js')
    let url =
      location.protocol +
      "//" +
      location.hostname +
      location.pathname +
      location.search;
    wxworkConfig({
      url: url,
      jsTicketType: "2", // 不为空 - 获取企业标识 为空 则为应用标识
    }).then((r) => {
      let d = r.data;
      wx.config({
        beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
        //debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: d.corpId, // 必填，公众号的唯一标识
        timestamp: d.timestamp, // 必填，生成签名的时间戳
        nonceStr: d.nonceStr, // 必填，生成签名的随机串
        signature: d.signature, // 必填，签名，见附录1
        jsApiList: [
          "checkJsApi",
          "startLiving",
          "replayLiving",
          "agentConfig",
          "invoke",
          "startMeeting",
        ],
        openTagList: ["wx-open-launch-app"],
      });
      wx.ready(() => {
        setTimeout(() => {
          wxworkConfig({ url: url }).then((res) => {
            const d = res.data;
            wx.agentConfig({
              corpid: d.corpId,
              agentid: d.agentid,
              timestamp: d.timestamp,
              nonceStr: d.nonceStr,
              signature: d.signature,
              jsApiList: [
                "checkJsApi",
                "startLiving",
                "replayLiving",
                "invoke",
                "startMeeting",
              ],
              success: function (res) {
                // 回调
                console.log("agentConfigSuccess：" + JSON.stringify(res));
              },
              fail: function (data) {
                console.log("agentConfigFail：" + JSON.stringify(data));
                if (data.errMsg.indexOf("function not exist") > -1) {
                  alert("版本过低请升级");
                }
              },
            });
          });
        }, 200);
      });
      wx.error((res) => {
        console.log(res, "---");
      });
    });
  },
  startLiving(roomId) {
    wx.ready(() => {
      wx.invoke(
        "startLiving",
        {
          livingId: roomId,
        },
        function (res) {
          if (res.err_msg != "replayLiving:ok") {
            // 错误处理
          }
        }
      );
    });
  },
  replayLiving(roomId) {
    wx.ready(() => {
      wx.invoke(
        "replayLiving",
        {
          livingId: roomId,
        },
        function (res) {
          if (res.err_msg != "replayLiving:ok") {
            // 错误处理
          }
        }
      );
    });
  },
  startMeeting(meetId) {
    wx.ready(() => {
      wx.invoke(
        "startMeeting",
        {
          meetingId: meetId,
        },
        function (res) {
          if (res.err_msg != "startMeeting:ok") {
            // 错误处理
          }
        }
      );
    });
  },
  wxCheckJsApi() {
    wx.checkJsApi({
      jsApiList: ["startLiving", "replayLiving", "checkJsApi"], // 需要检测的 JS 接口列表，所有 JS 接口列表见附录2,
      success: function (res) {
        alert(res);
        console.log(res, "--check---");
        // 以键值对的形式返回，可用的 api 值true，不可用为false
        // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
      },
    });
  },
};
export default obj;
